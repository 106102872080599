<template >
  <div class="containter">
    <!-- <div class="headArea">
      <img :src="backImg" style="width:100%;height:100%" />
    </div>-->
    <div class="titleFont">美丽城镇平台</div>
    <div style="width:100%;height:1rem;" class="noticeFont">初次登录美丽城镇平台需要进行本人操作身份验证。</div>
    <div style="width:100%;height:2rem;"></div>
    <div class="inputArea">
      <!-- <van-cell-group>
        <van-field v-model="uername" label="用户名" placeholder="请输入用户名" />
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="password" type="password" label="密码" placeholder="请输入密码" />
      </van-cell-group>-->
      <div class="inputLine">
        <div class="iconArea">
          <img src="./img/phone.png" style="height:30px;" />
        </div>
        <van-field v-model="uername" placeholder="输入手机号" />
      </div>
      <div class="inputLine">
        <div class="iconArea">
          <img src="./img/safeIcon.png" style="height:30px;" />
        </div>
        <van-field v-model="password" type="password" placeholder="输入密码" />
      </div>
      <!-- <div
        class="yanzhengmaArea"
        :style="status==0?'color:rgb(34, 57, 137);background-color:white':'color:white;background-color:rgb(34, 57, 137)'"
      >
        <div v-if="status==0" @click="getYanzhengma">获取验证码</div>
        <div v-else>{{num}}</div>
      </div>-->
      <!-- <van-cell-group>
        <van-field v-model="uername" label="手机号" placeholder="请输入" />
      </van-cell-group>
      <van-cell-group>
        <van-field v-model="password" type="password" label="验证码" placeholder="请输入" />
      </van-cell-group>-->
    </div>
    <div style="margin:0.5rem 0.8rem">
      <van-button
        style="width:100%;border-radius: 0.8rem;background-color:rgb(34, 57, 137);margin-top:0.5rem;"
        type="info"
        :disabled="status==0"
        @click="submitInfo"
      >登录</van-button>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'login',

  data() {
    return {
      num: 60,
      status: 1,
      uername: '',
      yanzhengma: '',
      password: '',
      backImg: require('./img/headPic.jpg')
    };
  },
  watch: {},
  created() {},
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getYanzhengma() {
      const this_ = this;
      this_.status = 1;
      this_.num = 60;
      const username = this_.uername;
      if (username.length != 11) {
        Notify({ type: 'warning', message: '手机号的格式不对！' });
        return false;
      }
      this_.$api.sendMessage({ username: username }).then(res => {
        if (res.data.success) {
          Notify({ type: 'success', message: '验证码发送成功' });
          localStorage.clear();
        } else {
          Notify({ type: 'warning', message: '验证码发送失败' });
          return false;
        }
      });
      const timer = setInterval(() => {
        if (this_.num == 0) {
          clearInterval(timer);
          this_.status = 0;
        }
        this_.num = this_.num - 1;
      }, 1000);
    },
    submitInfo() {
      localStorage.clear();
      const this_ = this;
      const uername = this_.uername;
      const password = this_.password;
      //   const yanzhengma = this_.yanzhengma;
      if (!uername || !password) {
        Notify({ type: 'warning', message: '请输入账号或密码' });
        return;
      }

      let param = {};
      param.username = uername;
      //   param.verificationCode = yanzhengma;
      param.password = password;
      this_.$api.loginEnterprise({ param: param }).then(res => {
        if (res.data.success) {
          const result = res.data.result;
          localStorage.setItem('X-Access-Token', result.token);
          if (result.isEnterprise) {
            localStorage.setItem('enterprise_if', 1);
            // localStorage.setItem('enterprise_List', JSON.stringify(result.enterpriseInfos));
            Notify({ type: 'success', message: '登录成功' });
            this_.$router.push({
              name: 'resumptionWorkCompanyList'
            });
            // localStorage.setItem('enterprise_name', result.enterpriseName);
            // localStorage.setItem('enterprise_code', result.enterpriseCode);
          } else {
            localStorage.setItem('enterprise_if', 2);
            Notify({ type: 'success', message: '登录成功' });
            this_.$router.push({ name: 'resumptionWork' });
          }
        } else {
          // Notify({ type: 'warning', message: '账号或密码错误' });
          Notify({ type: 'warning', message: '账号或密码错误' });
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
}
.headArea {
  width: 100%;
  height: 5rem;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.headPic {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
}
.titleFont {
  margin-top: 3rem;
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(34, 57, 137);
}
.noticeFont {
  text-align: center;
  font-size: 0.4rem;
}
.inputArea {
  margin: 0.5rem 0.8rem;
  position: relative;
}
.inputLine {
  width: 8.4rem;
  height: 1rem;
  margin-left: 0.8rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  /* border-bottom: rgb(119, 119, 119) 0.0267rem solid; */
}
.iconArea {
  width: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.yanzhengmaArea {
  position: absolute;
  top: 1.6rem;
  right: 0.4rem;
  width: 2rem;
  height: 0.6rem;
  font-size: 0.3rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  border: rgb(34, 57, 137) 0.0267rem solid;
}
</style>



